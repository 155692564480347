<template>
  <section class="tiny padded transparent main">
    <section class="v-spaced bordered padded">
      <h1><translate translate-context="*/*/*/Noun">Downloads</translate></h1>

      <p>
        Ask for the <a href="mailto:sarah.boujendar@tsm-education.fr">Vmocall TSM’s installer</a> and execute it.
      </p>
      <p>
        Alta Call TSM web applications (APIs and front-ends, including this web interface) are released under the Apache License 2.0 (Copyright 2023 Alta Voce) and are available at <a href="https://gitlab.com/altacalltsm/" target="_blank">https://gitlab.com/altacalltsm/</a>
      </p>
    </section>
  </section>
</template>